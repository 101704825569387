<template>
  <div class="integral-page-view">
    <div class="main-cont">
      <div class="bread-text">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/pointsMall' }">{{
            lang_data == 1 ? "积分商城" : "Integral mall"
          }}</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/recordExchange' }">{{
            lang_data == 1 ? "兑换记录" : "For record"
          }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{
            lang_data == 1 ? "订单详情" : "Order details"
          }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div
        v-loading="loading"
        :element-loading-text="lang_data == 1 ? '拼命加载中' : 'Loading'"
      >
        <div class="status-card mb10" v-if="detail">
          <img :src="getStatusIco(detail.status.key)" alt="" />
          <span style="margin-left: 10px"
            ><b>{{ detail.status.value }}</b></span
          >
        </div>
        <div class="exchange-panel">
          <div class="express-top-row" v-if="detail">
            <span class="mr20"
              >{{ lang_data == 1 ? "订单号" : "Order number" }}：{{
                detail.order_no
              }}
            </span>
            <span class="order-time"
              >{{ lang_data == 1 ? "下单时间" : "Place the order of time" }}：{{
                detail.add_time.value
              }}</span
            >
          </div>
          <div class="express-top-row" v-if="detail && detail.status.key > 1">
            <span class="mr20"
              >{{ lang_data == 1 ? "物流公司" : "Logistics company" }}：{{
                detail.express_company.value
              }}</span
            >
            <span
              >{{
                lang_data == 1 ? "物流单号" : "Logistics tracking number"
              }}：{{ detail.express_order_no }}</span
            >
          </div>

          <div class="sub-title" style="margin-top: 20px">
            <img
              class="sub-icon"
              src="~@/assets/img/Points mall-Order details-icon-add inf.png"
              alt=""
            />
            {{ lang_data == 1 ? "收货信息" : "Receiving information" }}
          </div>

          <div class="receiver_address mt10">
            <div>
              <label class="receiver-label"
                >{{ lang_data == 1 ? "收货人" : "Consignee" }}：</label
              >
              <b
                ><span class="receiver-content">{{
                  detail && detail.receiver_true_name
                }}</span></b
              >
            </div>
            <div>
              <label class="receiver-label"
                >{{ lang_data == 1 ? "联系电话" : "Phone" }}：</label
              >
              <b>
                <span class="receiver-content">{{
                  detail && detail.receiver_mobile
                }}</span>
              </b>
            </div>
            <div>
              <label class="receiver-label"
                >{{ lang_data == 1 ? "收货地址" : "Shipping address" }}：</label
              >
              <b>
                <p class="receiver-content">
                  {{ detail && detail.receiver_address }}
                </p>
              </b>
            </div>
          </div>

          <div class="sub-title mt20">
            <img
              class="sub-icon"
              src="~@/assets/img/Points mall-Order details-icon-commodity inf.png"
              alt=""
            />{{ lang_data == 1 ? "商品信息" : "Commodity information" }}
          </div>

          <div class="goods-box" v-if="detail">
            <div class="goods-avatar">
              <img
                v-if="detail.main_image_url"
                :src="detail.main_image_url"
                alt=""
              />
            </div>
            <div class="goods-info ml20">
              <div class="goods-title">
                <b style="font-size: 16px">{{ detail.goods_name }}</b>
                <span>
                  x <b>{{ detail.exchange_num }}</b></span
                >
              </div>
              <div class="goods-price">
                <img
                  src="~@/assets/img/Points mall-icon-Points details.png"
                  alt=""
                />
                <span style="margin-left: 4px"
                  ><b>{{ detail.exchange_one_integral.key }}</b></span
                ><span class="goods-old-price"
                  >￥{{ detail.goods_reference_price }}</span
                >
              </div>
            </div>
            <div class="goods-total">
              <span
                >{{ lang_data == 1 ? "积分合计" : "Integral combined" }}：</span
              >
              <span class="red-price">{{ detail.exchange_integral.key }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const statusConfig = {
  1: require("@/assets/img/Points mall-Order details-icon-To be shipped.png"), // 待发货
  2: require("@/assets/img/Points mall-Order details-icon-Shipped.png"), // 已发货
  3: require("@/assets/img/Points mall-Order details-icon-Completed.png"), // 已完成
};
export default {
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      detail: null,
      loading: false,
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getStatusIco(status) {
      return statusConfig[status];
    },
    changePage(val) {
      this.getList(val);
    },
    getDetail() {
      this.loading = true;
      let params = {
        id: this.$route.query.record_id,
      };
      this.$axios
        .get("/ucenter/exchange_integral_goods/getInfo", { params })
        .then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
          } else {
            this.detail = res.data;
          }
          this.loading = false;
        });
    },
    confirmAccept(item) {
      this.$confirm(
        this.lang_data == 1
          ? "确认已收到货品?"
          : "Acknowledge receipt of goods?"
      )
        .then(() => {
          let params = {
            id: item.id,
          };
          this.$axios
            .post("/ucenter/exchange_integral_goods/complete", params)
            .then((res) => {
              if (res.code == 1) {
                this.$message.success(res.msg);
                this.getList();
              } else {
                this.$message.error(res.msg);
              }
            });
        })
        .catch((err) => console.error(err));
    },
  },
};
</script>
<style lang="scss" scoped>
.integral-page-view {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  height: calc(100% - 70px);
  overflow: auto;
  .main-cont {
    width: 1300px;
    flex: 1;
    margin: 0 auto;
    padding-bottom: 30px;
    .bread-text {
      font-weight: bold;
      color: #666666;
      line-height: 20px;
      margin: 20px 0;
    }

    .status-card {
      width: 1300px;
      height: 60px;
      background: linear-gradient(135deg, #00adff 0%, #409eff 100%);
      box-shadow: 0px 1px 5px 0px rgba(227, 228, 230, 0.48);
      border-radius: 8px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      color: #ffffff;
      padding: 0 20px;
      font-size: 16px;
    }
    .exchange-panel {
      width: 1300px;
      //   height: 676px;
      background: #ffffff;
      box-shadow: 0 0 12px 0#e4e4e4;
      border: 1px solid #e4e4e4;
      padding: 10px 20px;
      border-radius: 8px;
      box-sizing: border-box;
      .express-top-row {
        font-size: 15px;
        height: 60px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e1e1e5;
        font-weight: bold;
      }

      .sub-title {
        font-size: 16px;
        font-weight: bold;
        color: #333;
        position: relative;
        padding-left: 20px;

        .sub-icon {
          position: absolute;
          top: 50%;
          left: 0;
          width: 16px;
          height: 16px;
          transform: translateY(-50%);
        }
      }

      .receiver_address {
        color: #606163;
        line-height: 24px;
        .receiver-label {
          width: 100px;
          display: inline-block;
          text-align: justify;
          text-justify: distribute-all-lines;
          text-align-last: justify;
        }
        .receiver-content {
          display: inline-block;
        }
      }

      .goods-box {
        margin: 20px auto;
        border-radius: 4px;
        border: 1px solid #e1e1e5;
        display: flex;
        align-items: center;
        padding: 12px 20px 12px 12px;
        box-sizing: border-box;
        .goods-avatar {
          width: 72px;
          height: 72px;
          background: #eee;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .goods-info {
          max-width: 400px;
          height: 72px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .goods-price {
            color: #409eff;
            display: flex;
            align-items: center;
            img {
              width: 14px;
            }
            .goods-old-price {
              color: #b0b1b5;
              text-decoration: line-through;
              margin-left: 14px;
            }
          }
        }
        .goods-total {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 14px;
          color: #333;
          .red-price {
            color: #409eff;
            font-weight: bold;
            font-size: 20px;
          }
        }
      }
    }
  }
}
</style>